:root {
  --amplify-primary-color: #0068c2;
  --amplify-primary-tint: #00427f;
  --amplify-primary-shade: #16a7e7;
}

amplify-authenticator {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100vh;
}